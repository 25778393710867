import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of } from 'rxjs';
import { env } from '../config/config';

@Injectable({
    providedIn: 'root'
})
export class ArticleService {

    constructor(private http: HttpClient) { }

    getArticleList(journal_id: string, data: any): Observable<any> {
   
        return this.http.get<any>(
            `${env.siteApiUrl}web/${journal_id}/articles`,
            {
                params: data
            }
        );
    }
    

    getArticleViewData(queryParams:any): Observable<any> {
        return this.http.get<any>(
            env.siteApiUrl + 'web/article/data',
            {
                params: queryParams,
            }
        );
    }
    getArticles(searchValue: any, category: string): Observable<any> {
        return this.http.get<any>(
            `${env.siteApiUrl}web/search-articles`,
            {
                params: { 
                    search: searchValue,
                    category: category
                }
            }
        );
    }
    checkArticleExists(category: string,subcategory:string, articleLink: string): Observable<boolean> {
        return this.http.get<any>(`${env.siteApiUrl}web/articles-exists`, {
            params: {
                articleLink: articleLink,
                category: category,
                subcategory:category
            }
        }).pipe(
            map(response => response.exists), // Adjust according to the response format
            catchError(() => {
                // Handle error, log it, or redirect as needed
                return of(false);
            })
        );
    }

    getAllCategories(): Observable<any[]> {
        return this.http.get<any[]>(`${env.siteApiUrl}web/menus`);
      }

    getRelatedArticleList(data: any): Observable<any> {
        return this.http.get<any>(
           env.siteApiUrl+'web/article/bjs-related-article',
            {
                params: data
            }
        );
    }

 

getFilteredArticles(journal_id: string, data: any): Observable<any> {
        return this.http.get<any>(
            `${env.siteApiUrl}web/${journal_id}/filter-articles`,
            {
                params: data
            }
        );
    }
    getCategoryContent(category:any): Observable<any> {
        return this.http.get<any>(
           env.siteApiUrl+'web/category-content',
            {
                params: category
            }
        );
    }

    getFilterData(): Observable<any> {

        return this.http.get<any>(
            env.siteApiUrl+'web/filter-items');
      }

    

    getLatestArticles(journal_id:string,data:any):Observable<any>{
        return this.http.get<any>(
            `${env.siteApiUrl}web/${journal_id}/latest-articles`,
            {
                params: data
            }
        );
    }
    getCategoryData(data:any): Observable<any> {
        return this.http.get<any>(
           env.siteApiUrl+'web/category-data',
            {
                params: data
            }
        );
    }
    updateLikeCount(payload:any):Observable<any> {
        return this.http.post<any>(env.siteApiUrl + "web/articleLikeCountChanges", {
            params: payload
        });
    }
    
}
