import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges, OnInit, viewChild, ViewChild, Renderer2, HostListener, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { ArticleService } from '../../services/article.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryComponent } from '../category/category.component';
import { isPlatformBrowser } from '@angular/common';


@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrl: './banner.component.scss'
})
export class BannerComponent implements OnInit {
  @Input() pageType = '';
  @Input() BannerHeading = '';
  @Input() BannerImage = '';
  // @Input() articles: any;
  @Input() contentType: any;
  @Input() surgicalSpecialty: any;
  @Input() pageloading: boolean;

  BannerHeadingfirstPart = '';
  BannerHeadinglastPart = '';
  contentHeight = '';
  @Output() searchEvent = new EventEmitter<string>();
  @ViewChild('maindiv') maindivElement!: ElementRef<HTMLDivElement>;  // Ensure it's non-null assertion


  //  CategoryarrayForListing = ['Young BJS'];
  categories: any[] = [];
  categoryTitles: string[] = [];
  category: string = '';
  categoryTitle: string[] = [];
  CategoryarrayForListing: string[] = [];
  categoryContent: any;
  contentloading = true;
  isSticky: boolean = false;
  screeWidth=0;
  constructor(
    private articleService: ArticleService,
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId: any
  ) { 
    if (isPlatformBrowser(this.platformId)) {
      this.screeWidth=window.innerWidth;
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (isPlatformBrowser(this.platformId)) {
      this.screeWidth=window.innerWidth;
    }

  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  
    // Dynamically calculate the navbar's height
    const navbar = document.querySelector('.navbar') as HTMLElement;  // Cast Element to HTMLElement
    const navbarHeight = navbar ? navbar.offsetHeight : 100;  // Fallback to 100px if navbar is not found
  
    this.isSticky = scrollTop > 300; // Adjust the scroll position for sticky behavior
    
    if (this.isSticky && this.maindivElement && this.screeWidth>576) {
      // Sticky behavior: Set 'fixed' position and remove 'bottom' style
      this.renderer.setStyle(this.maindivElement.nativeElement, 'position', 'fixed');
      this.renderer.setStyle(this.maindivElement.nativeElement, 'top', `${navbarHeight}px`);
      // this.renderer.setStyle(this.maindivElement.nativeElement, 'z-index', '1');
      this.renderer.setStyle(this.maindivElement.nativeElement, 'bottom','0px'); // Remove 'bottom' when sticky
      this.renderer.setStyle(this.maindivElement.nativeElement, 'height','fit-content'); 
      this.renderer.setStyle(this.maindivElement.nativeElement, 'margin-left','-1rem'); 
      this.renderer.setStyle(this.maindivElement.nativeElement, 'width',this.screeWidth>=1200?'85%':'100%'); 
      this.renderer.setStyle(this.maindivElement.nativeElement, 'border-radius', '0px');

    } else if (!this.isSticky && this.maindivElement &&  this.screeWidth>576) {
      // Restore to default behavior: Set 'relative' position and restore 'bottom' style
      this.renderer.setStyle(this.maindivElement.nativeElement, 'position', 'relative');
      this.renderer.setStyle(this.maindivElement.nativeElement, 'margin-left', '5rem');
      this.renderer.setStyle(this.maindivElement.nativeElement, 'margin-right', '5rem');
       this.renderer.setStyle(this.maindivElement.nativeElement, 'bottom', '90px');  // Restore 'bottom'
      // this.renderer.removeStyle(this.maindivElement.nativeElement, 'z-index');
      this.renderer.removeStyle(this.maindivElement.nativeElement, 'height');
      this.renderer.removeStyle(this.maindivElement.nativeElement, 'top');  // Remove 'top' when not sticky
      this.renderer.setStyle(this.maindivElement.nativeElement, 'border-radius', '15px');
      this.renderer.removeStyle(this.maindivElement.nativeElement, 'width'); 
    }
  }
  
  ngOnInit(): void {
    console.log(this.pageType)
    this.route.params.subscribe(params => {
      if (params['subcategory']) {
        this.category = params['subcategory'];

      } else {
        this.category = params['category'];

      }
      this.getCategories();
      if (this.category) {
        this.getCategoryContent(this.category);
      }
    });
    this.splitText()

  }


  getCategories(): void {
    this.articleService.getAllCategories().subscribe((response: any) => {
      // Assuming "academy" is the identifier for the main "Academy" menu
      const academyMenu = response.find((menu: any) => menu.identifier === 'bjs-academy');

      if (academyMenu) {
        // Filter out "Scientific Surgery" submenu
        const filteredSubMenus = academyMenu.subMenus.filter((subMenu: any) => subMenu.identifier !== 'scientific-surgery');

        // Include "Scientific Surgery" submenus
        const scientificSurgeryMenu = academyMenu.subMenus.find((subMenu: any) => subMenu.identifier === 'scientific-surgery');
        if (scientificSurgeryMenu) {
          filteredSubMenus.push(...scientificSurgeryMenu.subMenus);
        }

        // Update categories and categoryTitles
        this.categories = filteredSubMenus;
        this.categoryTitles = ['All', ...this.categories.map((subMenu: any) => subMenu.menu_name)];
        //this.setCategoryTitle();
      }
    });
  }


  setCategoryTitle(): void {
    if (this.category && this.categories.length > 0) {
      const matchedCategory = this.categories.find(cat => cat.identifier === this.category);
      if (matchedCategory) {
        const subMenus = matchedCategory.subMenus.filter((subMenu: { identifier: string; }) => subMenu.identifier !== 'scientific-surgery');
        this.categoryTitle = [matchedCategory.menu_name];
        this.CategoryarrayForListing = subMenus.map((subMenu: { menu_name: any; }) => subMenu.menu_name);

      } else {
        this.categoryTitle = [];
        this.CategoryarrayForListing = [];
      }
    } else {
      this.categoryTitle = [];
      this.CategoryarrayForListing = [];
    }
  }

  getBackgroundStyle() {
    this.pageloading = false;
    return this.pageType === 'homepage' || this.pageType === 'academy' ? {
      'background-image': 'url("' + this.BannerImage + '")',
      'background-size': 'cover',
      'background-position': 'center',
      'background-position-y': this.pageType === 'academy' ? '2rem' : '0',
      'background-repeat': 'no-repeat',
      'height': '450px'
    } : {
      'height': 'auto'
    };
  }
  splitText() {
    const words = this.BannerHeading.split(' ');
    if (words.length > 1) {
      this.BannerHeadingfirstPart = words.slice(0, -1).join(' ');
      this.BannerHeadinglastPart = words[words.length - 1];
    } else {
      this.BannerHeadingfirstPart = this.BannerHeading;
      this.BannerHeadinglastPart = '';
    }


  }


  onSearchEvent(searchValue: string) {
    this.searchEvent.emit(searchValue);
  }
  getCategoryContent(category: string) {
    const data = {
      category: category
    }
    this.articleService.getCategoryContent(data).subscribe((response: any) => {
      this.categoryContent = response.data;
      this.contentloading = false;

    });

  }
  goto(url: string) {

    this.router.navigate([url])
  }
  formatText(text: string): string {
    if (text) {
      if (text == 'young-bjs') {
        return text.replace(/(\w+)-(\w+)/, (match, p1, p2) => {
          const firstPart = p1.charAt(0).toUpperCase() + p1.slice(1); // Capitalize first word
          const secondPart = p2.toUpperCase(); // Convert second part to uppercase
          return `${firstPart} ${secondPart}`;
        });
      }
      else {
        const formattedText = text.replace(/-/g, ' '); // Replace all hyphens with spaces
        const capitalizedText = formattedText.charAt(0).toUpperCase() + formattedText.slice(1); // Capitalize the first letter
        const returnText = capitalizedText.length > 25 ? capitalizedText.substring(0, 25) + '...' : capitalizedText;

        return returnText;
      }
    }
    return null;
  }
  replaceHypen(text: string) {
    if (text) {
      if (text == 'young-bjs') {
        return text.replace(/(\w+)-(\w+)/, (match, p1, p2) => {
          const firstPart = p1.charAt(0).toUpperCase() + p1.slice(1); // Capitalize first word
          const secondPart = p2.toUpperCase(); // Convert second part to uppercase
          return `${firstPart} ${secondPart}`;
        });
      }
      else {

        const formattedText = text.replace(/-/g, ' '); // Replace all hyphens with spaces
        const capitalizedText = formattedText.charAt(0).toUpperCase() + formattedText.slice(1); // Capitalize the first letter
        // const returnText = capitalizedText.length > 25 ? capitalizedText.substring(0, 25) + '...' : capitalizedText;

        return capitalizedText;
      }
    }
    return null;
  }

}
