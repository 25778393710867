<div class="col" #maindiv *ngIf="!pageloading;else PageNotLoaded">
    <div class="card article-card" *ngIf="pageType=='homepage' || pageType=='allpage' ;else notHomePage">
        <span class="article_cover_img_card"
            (click)="navigateToArticle(article.article_link,article.category_heading.id)">
            <!-- <img *ngIf="article.article_cover_image" 
            [src]="coverImageUrl" 
            (click)="navigateToArticle(article.article_link,article.category_heading.id)"
            class="card-img-top article-img" [alt]="{{article.article_title}}">

            <img *ngIf="article.article_cover_image==null" src="bjs_article_cover_image.png" 
            class="card-img-top article-img" [alt]="{{article.article_title}}"> -->

            <img *ngIf="article.article_cover_image" src="{{coverImageUrl}}" class="card-img-top article-img"
                alt="{{article.article_title}}" srcset="{{coverImageUrl}} 1200w, 
                {{coverImageUrl}} 300w, 
                {{coverImageUrl}} 1024w, 
                {{coverImageUrl}} 768w" data-src="{{coverImageUrl}}" data-srcset="{{coverImageUrl}} 1200w, 
                {{coverImageUrl}} 300w, 
                {{coverImageUrl}} 1024w, 
                {{coverImageUrl}} 768w" decoding="async" sizes="(max-width: 1200px) 100vw, 1200px">

            <img *ngIf="article.article_cover_image==null " src="bjs_article_cover_image.png"
                class="card-img-top article-img" alt="{{article.article_title}}" srcset="bjs_article_cover_image.png 1200w, 
                bjs_article_cover_image.png 300w, 
                bjs_article_cover_image.png 1024w, 
                bjs_article_cover_image.png 768w" data-src="bjs_article_cover_image.png" data-srcset="bjs_article_cover_image.png 1200w, 
                bjs_article_cover_image.png 300w, 
                bjs_article_cover_image.png 1024w, 
                bjs_article_cover_image.png 768w" decoding="async" sizes="(max-width: 1200px) 100vw, 1200px">
        </span>
        <div class="card-body">
            <div class="ellipsis-div">

                <h2 class="card-title" (click)="navigateToArticle(article.article_link,article.category_heading.id)"
                    title="{{article.article_title}}" [innerHTML]="safeTitle"></h2>
                <p *ngIf="article.authors && article.authors.length > 0" class="card-text">
                    <!-- Use helper methods to check the type of authors array -->
                    <span *ngIf="isStringArray(article.authors)" [innerHtml]="article.authors.join(', ')">
                    </span>
                    <span *ngIf="isObjectArray(article.authors)">
                        <span *ngFor="let author of article.authors; let isLast = last" [innerHtml]=" author.name ">
                            <span *ngIf="!isLast">, </span>
                        </span>
                    </span>
                </p>

                <p class="card-content" [innerHTML]="safeSummary ?? article?.abstract"></p>
            </div>

            <div class="d-flex flex-row align-items-center">
                <div class="card-tag" #content>
                    <a *ngFor="let tag of article.surgical_speciality" class="tag-content">
                        <span [innerHTML]="highlightReference(tag)"
                            (click)="filterClicked(tag, 'speciality', article.category_heading.id);highlightReference(tag)"></span>
                    </a>

                    <a *ngFor="let type of article.content_type" class="tag-content">
                        <span [innerHTML]="highlightType(type)"
                            (click)="filterClicked(type, 'type');highlightType(type)"></span>
                    </a>



                    <!-- <a *ngIf="returnCategory(article.category_heading.id)" class="tag-content">
                        <span [innerHTML]="highlightReferenceHeading(article.category_heading.title)" 
                              (click)="categoryClick(article.category_heading.id, article.category_heading.title)">
                        </span>
                    </a> -->
                    <a *ngIf="returnCategory(article.category_heading.id)" class="tag-content">
                        <span [innerHTML]="highlightReferenceHeading(article.category_heading.title)"
                            (click)="categoryClick(article.category_heading.id, article.category_heading.title)">
                        </span>
                    </a>

                </div>

                <div class="txtcol" #showMore (click)="toggleShowMore(maindiv,$event)"><a>More...</a></div>
            </div>
            <!-- <div class="txtcol" (click)="toggleShowMore(maindiv,$event)"><a>Show more...</a>
            </div> -->
            <span class="card-date" *ngIf="article.published_online">
                <div class="like-button">
                    <div class="badge-container">
                        <fa-icon [icon]="['far', 'thumbs-up']" class="share-icon" *ngIf="!isArticleLiked"
                            (click)="addOrRemoveArticleLike(article.journal_id,article.category_heading.id,article.article_id)"></fa-icon>
                        <fa-icon [icon]="['fas', 'thumbs-up']" class="share-icon" *ngIf="isArticleLiked"
                            (click)="addOrRemoveArticleLike(article.journal_id,article.category_heading.id,article.article_id)"></fa-icon>
                        <span class="badge" *ngIf="showLikeCount">({{ formattedLikeCount }})</span>

                    </div>
                </div>
                <div>
                    <fa-icon [icon]="['fas','calendar-days']" class="icon-date"></fa-icon>{{ article.published_online }}
                </div>
            </span>
            <span class="card-date" *ngIf="article.pub_date">
                <div>
                    <fa-icon [icon]="['fas','calendar-days']" class="icon-date"></fa-icon>{{ article.published_online }}

                </div>
            </span>
        </div>
    </div>
    <ng-template #notHomePage>
        <div class="card article-card"
            *ngIf=" article.category_heading.id !=='randomized-clinical-trials' ;else isRCTArticle">
            <span class="article_cover_img_card"
                (click)="navigateToArticle(article.article_link,article.category_heading.id)">
                <!-- <img *ngIf="article.article_cover_image" 
                 [src]="coverImageUrl" 
                 (click)="navigateToArticle(article.article_link,article.category_heading.id)"
                 class="card-img-top article-img" [alt]="{{article.article_title}}">
     
                 <img *ngIf="article.article_cover_image==null" src="bjs_article_cover_image.png" 
                 class="card-img-top article-img" [alt]="{{article.article_title}}"> -->

                <img *ngIf="article.article_cover_image" src="{{coverImageUrl}}" class="card-img-top article-img"
                    alt="{{article.article_title}}" srcset="{{coverImageUrl}} 1200w, 
                     {{coverImageUrl}} 300w, 
                     {{coverImageUrl}} 1024w, 
                     {{coverImageUrl}} 768w" data-src="{{coverImageUrl}}" data-srcset="{{coverImageUrl}} 1200w, 
                     {{coverImageUrl}} 300w, 
                     {{coverImageUrl}} 1024w, 
                     {{coverImageUrl}} 768w" decoding="async" sizes="(max-width: 1200px) 100vw, 1200px">

                <img *ngIf="article.article_cover_image==null " src="bjs_article_cover_image.png"
                    class="card-img-top article-img" alt="{{article.article_title}}" srcset="bjs_article_cover_image.png 1200w, 
                     bjs_article_cover_image.png 300w, 
                     bjs_article_cover_image.png 1024w, 
                     bjs_article_cover_image.png 768w" data-src="bjs_article_cover_image.png" data-srcset="bjs_article_cover_image.png 1200w, 
                     bjs_article_cover_image.png 300w, 
                     bjs_article_cover_image.png 1024w, 
                     bjs_article_cover_image.png 768w" decoding="async" sizes="(max-width: 1200px) 100vw, 1200px">
            </span>
            <div class="card-body">
                <div class="ellipsis-div">

                    <h2 class="card-title" (click)="navigateToArticle(article.article_link,article.category_heading.id)"
                        title="{{article.article_title}}" [innerHTML]="safeTitle"></h2>
                    <p *ngIf="article.authors && article.authors.length > 0" class="card-text">
                        <!-- Use helper methods to check the type of authors array -->
                        <span *ngIf="isStringArray(article.authors)" [innerHtml]="article.authors.join(', ')">
                        </span>
                        <span *ngIf="isObjectArray(article.authors)">
                            <span *ngFor="let author of article.authors; let isLast = last" [innerHtml]=" author.name ">
                                <span *ngIf="!isLast">, </span>
                            </span>
                        </span>
                    </p>

                    <p class="card-content" [innerHTML]="safeSummary"></p>
                </div>

                <div class="d-flex flex-row align-items-center">
                    <div class="card-tag" #content>
                        <a *ngFor="let tag of article.surgical_speciality" class="tag-content">
                            <span [innerHTML]="highlightReference(tag)"
                                (click)="filterClicked(tag, 'speciality', article.category_heading.id);highlightReference(tag)"></span>
                        </a>

                        <a *ngFor="let type of article.content_type" class="tag-content">
                            <span [innerHTML]="highlightType(type)"
                                (click)="filterClicked(type, 'type');highlightType(type)"></span>
                        </a>



                        <!-- <a *ngIf="returnCategory(article.category_heading.id)" class="tag-content">
                            <span [innerHTML]="highlightReferenceHeading(article.category_heading.title)" 
                                  (click)="categoryClick(article.category_heading.id, article.category_heading.title)">
                            </span>
                        </a> -->
                        <a *ngIf="returnCategory(article.category_heading.id)" class="tag-content">
                            <span [innerHTML]="highlightReferenceHeading(article.category_heading.title)"
                                (click)="categoryClick(article.category_heading.id, article.category_heading.title)">
                            </span>
                        </a>

                    </div>

                    <div class="txtcol" #showMore (click)="toggleShowMore(maindiv,$event)"><a>More...</a></div>
                </div>
                <!-- <div class="txtcol" (click)="toggleShowMore(maindiv,$event)"><a>Show more...</a>
                </div> -->
                <span class="card-date" *ngIf="article.published_online">
                    <div class="like-button">
                        <div class="badge-container">
                            <fa-icon [icon]="['far', 'thumbs-up']" class="share-icon" *ngIf="!isArticleLiked"
                                (click)="addOrRemoveArticleLike(article.journal_id,article.category_heading.id,article.article_id)"></fa-icon>
                            <fa-icon [icon]="['fas', 'thumbs-up']" class="share-icon" *ngIf="isArticleLiked"
                                (click)="addOrRemoveArticleLike(article.journal_id,article.category_heading.id,article.article_id)"></fa-icon>
                            <span class="badge" *ngIf="showLikeCount">({{ formattedLikeCount }})</span>

                        </div>
                    </div>
                    <div>
                        <fa-icon [icon]="['fas','calendar-days']" class="icon-date"></fa-icon>{{
                        article.published_online }}
                    </div>
                </span>
                <span class="card-date" *ngIf="article.pub_date">
                    <div>
                        <fa-icon [icon]="['fas','calendar-days']" class="icon-date"></fa-icon>{{
                        article.published_online }}

                    </div>
                </span>
            </div>
        </div>
        <ng-template #isRCTArticle>

            <div class="card article-card">
                <div class="card-body">
                    <div class="ellipsis-div">

                        <h2 class="card-title"
                            (click)="navigateToArticle(article.article_link,article.category_heading.id)"
                            title="{{article.article_title}}" [innerHTML]="safeTitle"></h2>
                        <p *ngIf="article.authors && article.authors.length > 0" class="card-text">
                            <!-- Use helper methods to check the type of authors array -->
                            <span *ngIf="isStringArray(article.authors)" [innerHtml]="article.authors.join(', ')">
                            </span>
                            <span *ngIf="isObjectArray(article.authors)">
                                <span *ngFor="let author of article.authors; let isLast = last"
                                    [innerHtml]=" author.name ">
                                    <span *ngIf="!isLast">, </span>
                                </span>
                            </span>
                        </p>

                        <p class="card-content" [innerHTML]="safeSummary"></p>
                    </div>

                    <div class="d-flex flex-row align-items-center">
                        <div class="card-tag" #content>
                            <a *ngFor="let tag of article.surgical_speciality" class="tag-content">
                                <span [innerHTML]="highlightReference(tag)"
                                    (click)="filterClicked(tag, 'speciality', article.category_heading.id);highlightReference(tag)"></span>
                            </a>

                            <a *ngFor="let type of article.content_type" class="tag-content">
                                <span [innerHTML]="highlightType(type)"
                                    (click)="filterClicked(type, 'type');highlightType(type)"></span>
                            </a>



                            <!-- <a *ngIf="returnCategory(article.category_heading.id)" class="tag-content">
                                <span [innerHTML]="highlightReferenceHeading(article.category_heading.title)" 
                                      (click)="categoryClick(article.category_heading.id, article.category_heading.title)">
                                </span>
                            </a> -->
                            <a *ngIf="returnCategory(article.category_heading.id)" class="tag-content">
                                <span [innerHTML]="highlightReferenceHeading(article.category_heading.title)"
                                    (click)="categoryClick(article.category_heading.id, article.category_heading.title)">
                                </span>
                            </a>

                        </div>

                        <div class="txtcol" #showMore (click)="toggleShowMore(maindiv,$event)"><a>More...</a></div>
                    </div>
                    <!-- <div class="txtcol" (click)="toggleShowMore(maindiv,$event)"><a>Show more...</a>
                    </div> -->
                    <span class="card-date" *ngIf="article.published_online">
                        <div class="like-button">
                            <div class="badge-container">
                                <fa-icon [icon]="['far', 'thumbs-up']" class="share-icon" *ngIf="!isArticleLiked"
                                    (click)="addOrRemoveArticleLike(article.journal_id,article.category_heading.id,article.article_id)"></fa-icon>
                                <fa-icon [icon]="['fas', 'thumbs-up']" class="share-icon" *ngIf="isArticleLiked"
                                    (click)="addOrRemoveArticleLike(article.journal_id,article.category_heading.id,article.article_id)"></fa-icon>
                                <span class="badge" *ngIf="showLikeCount">({{ formattedLikeCount }})</span>

                            </div>
                        </div>
                        <div>
                            <fa-icon [icon]="['fas','calendar-days']" class="icon-date"></fa-icon>{{
                            article.published_online }}
                        </div>
                    </span>
                    <span class="card-date" *ngIf="article.pub_date">
                        <div>
                            <fa-icon [icon]="['fas','calendar-days']" class="icon-date"></fa-icon>{{
                            article.published_online }}

                        </div>
                    </span>
                </div>
            </div>
        </ng-template>
    </ng-template>



</div>
<ng-template #PageNotLoaded>
    <div class="card  article-card">

        <div class="card-img-top skeleton skeleton-img">

        </div>
        <div class="card-body">

            <h5 class="skeleton-card-title skeleton skeleton-text "></h5>
            <p class="skeleton-author skeleton skeleton-text skeleton-text-80"></p>
            <p class=" skeleton skeleton-text"></p>
            <p class=" skeleton skeleton-text"></p>
            <p class=" skeleton skeleton-text"></p>
            <p class=" skeleton skeleton-text skeleton-text-80 "></p>
            <div class="row show-less">
                <div class="col-xxl-10 col-xl-12 col-lg-12 col-md-12 col-sm-10 ">
                    <div class="badges ml-2 overflow ">
                        <div class=" badge rounded-pill bg-primary article-badge skeleton skeleton-badge">
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;

                        </div>
                        &nbsp;
                        <div class=" badge rounded-pill bg-primary article-badge skeleton skeleton-badge">
                            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        </div>

                    </div>

                </div>

            </div>


            <div class="pub-date mt-auto d-flex justify-content-end skeleton skeleton-date" tabindex="0">
                &nbsp; &nbsp;
            </div>
        </div>
    </div>
</ng-template>