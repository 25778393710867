import { Component, EventEmitter, Input, Output, OnChanges, SimpleChanges, OnInit, ViewChild, ElementRef, AfterViewInit, HostListener, Inject, PLATFORM_ID, Renderer2 } from '@angular/core';
import { ArticleService } from '../../services/article.service';
import { ActivatedRoute } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
declare var bootstrap: any;

@Component({
  selector: 'app-home-banner',
  templateUrl: './home-banner.component.html',
  styleUrl: './home-banner.component.scss'
})
export class HomeBannerComponent implements OnInit, AfterViewInit {
  @Input() pageType = '';
  @Input() BannerHeading = '';
  @Input() BannerImage = '';
  @ViewChild('bannerCarousel') bannerCarousel: ElementRef<HTMLElement>;
  @ViewChild('maindiv') maindivElement!: ElementRef<HTMLDivElement>;  // Ensure it's non-null assertion

  BannerHeadingfirstPart = '';
  BannerHeadinglastPart = '';
  contentHeight = '';

  @Output() searchEvent = new EventEmitter<string>();

  //  CategoryarrayForListing = ['Young BJS'];
  categories: any[] = [];
  categoryTitles: string[] = [];
  category: string = '';
  categoryTitle: string[] = [];
  CategoryarrayForListing: string[] = [];
  categoryContent: any;
  pageloading = true;
  carouselHeight = 0;
  extraCategory: string[] = [];
  isSticky: boolean = false;
  screeWidth = 0;
  private lastScrollTop = 0;
  constructor(
    private articleService: ArticleService,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    if (isPlatformBrowser(this.platformId)) {
      // this.screenWidth = event.target.window.innerWidth;
      this.screeWidth = window.innerWidth;
    }
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    if (isPlatformBrowser(this.platformId)) {
      // this.screenWidth = event.target.window.innerWidth;
      this.carouselHeight = this.bannerCarousel.nativeElement.offsetHeight;
      this.screeWidth = window.innerWidth;
      const navbar = document.querySelector('.navbar') as HTMLElement;  // Cast Element to HTMLElement
      const navbarHeight = navbar ? navbar.offsetHeight : 100;  // Fallback to 100px if navbar is not found
      // if (this.screeWidth < 576) {
        this.restroreSearchBar();
      // }


      //  this.searchScrollBehaveFunction(navbarHeight);

    }


  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const currentScroll = window.pageYOffset || document.documentElement.scrollTop;
    if (Math.abs(currentScroll - this.lastScrollTop) > 200) {
      this.lastScrollTop = currentScroll;
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const navbar = document.querySelector('.navbar') as HTMLElement;  // Cast Element to HTMLElement
      const navbarHeight = navbar ? navbar.offsetHeight : 100;  // Fallback to 100px if navbar is not found
      this.isSticky = scrollTop > 900; // Adjust the scroll position for sticky behavior
      this.searchScrollBehaveFunction(navbarHeight)
    }
  
   
  }
  searchScrollBehaveFunction(navbarHeight: number) {
    if (this.isSticky && this.maindivElement && this.screeWidth > 576) {

      // Sticky behavior: Set 'fixed' position and remove 'bottom' style
      this.renderer.setStyle(this.maindivElement.nativeElement, 'position', 'fixed');
      this.renderer.setStyle(this.maindivElement.nativeElement, 'top', `${navbarHeight}px`);
      // this.renderer.setStyle(this.maindivElement.nativeElement, 'z-index', '1');
      this.renderer.setStyle(this.maindivElement.nativeElement, 'bottom', '0px'); // Remove 'bottom' when sticky
      this.renderer.setStyle(this.maindivElement.nativeElement, 'height', 'fit-content');
      this.renderer.setStyle(this.maindivElement.nativeElement, 'margin-left', 'auto');
      this.renderer.setStyle(this.maindivElement.nativeElement, 'width', this.screeWidth < 1200 ? '98%' : '85%');
      this.renderer.setStyle(this.maindivElement.nativeElement, 'border-radius', '0px');

    } else if (!this.isSticky && this.maindivElement && this.screeWidth > 576) {
      this.restroreSearchBar();
    }
    else{
      this.restroreSearchBar();
    }
  }
  restroreSearchBar() {
    // Restore to default behavior: Set 'relative' position and restore 'bottom' style
    this.renderer.setStyle(this.maindivElement.nativeElement, 'position', 'relative');
    this.renderer.setStyle(this.maindivElement.nativeElement, 'margin-left', 'auto');
    this.renderer.setStyle(this.maindivElement.nativeElement, 'margin-right', 'auto');
    this.renderer.setStyle(this.maindivElement.nativeElement, 'bottom',
      this.screeWidth < 400 ? '22px' : this.screeWidth < 575 ? '27px'
        : this.screeWidth < 768 ? '41px' : this.screeWidth > 768 ?'90px':'90px');  // Restore 'bottom'
    // this.renderer.removeStyle(this.maindivElement.nativeElement, 'z-index');
    this.renderer.removeStyle(this.maindivElement.nativeElement, 'height');
    this.renderer.removeStyle(this.maindivElement.nativeElement, 'top');
    this.renderer.setStyle(this.maindivElement.nativeElement, 'width', '85%');
    this.renderer.setStyle(this.maindivElement.nativeElement, 'border-radius', '15px');
  }




  ngOnInit(): void {

    this.getCategories();
    this.splitText()
    if (this.BannerImage) {
      this.pageloading = false;

    }
  }
  ngAfterViewInit(): void {
    if (this.bannerCarousel) {
      this.carouselHeight = this.bannerCarousel.nativeElement.offsetHeight;
      const myCarouselElement = this.bannerCarousel.nativeElement;
      const carousel = new bootstrap.Carousel(myCarouselElement, {
        interval: 15000,
        touch: false
      });
    }
  }
  getCategories(): void {
    this.articleService.getAllCategories().subscribe((response: any) => {
      // Assuming "academy" is the identifier for the main "Academy" menu
      const academyMenu = response.find((menu: any) => menu.identifier === 'bjs-academy');

      if (academyMenu) {
        // Filter out "Scientific Surgery" submenu
        const filteredSubMenus = academyMenu.subMenus.filter((subMenu: any) => subMenu.identifier !== 'scientific-surgery');

        // Include "Scientific Surgery" submenus
        const scientificSurgeryMenu = academyMenu.subMenus.find((subMenu: any) => subMenu.identifier === 'scientific-surgery');

        if (scientificSurgeryMenu) {
          this.extraCategory.push(...scientificSurgeryMenu.subMenus);
        }

        // Update categories and categoryTitles
        this.categories = filteredSubMenus;

        this.categoryTitles = ['All', ...this.categories.map((subMenu: any) => subMenu.menu_name)];

        //this.setCategoryTitle();
      }
    });
  }



  getBackgroundStyle() {
    const style = this.pageType === 'homepage' ? {
      'background-image': 'url("' + this.BannerImage + '")',
      'background-size': 'cover',
      'background-position': 'center',
      'height': '640px'
    } : {
      //  'height': 'auto'
    };


    return style;
  }
  splitText() {
    const words = this.BannerHeading.split(' ');
    if (words.length > 1) {
      this.BannerHeadingfirstPart = words.slice(0, -1).join(' ');
      this.BannerHeadinglastPart = words[words.length - 1];
    } else {
      this.BannerHeadingfirstPart = this.BannerHeading;
      this.BannerHeadinglastPart = '';
    }
  }

  onSearchEvent(searchValue: string) {
    this.searchEvent.emit(searchValue);
  }

}
